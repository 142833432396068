import { twMerge } from "tailwind-merge";

type ForestProps = {
  className?: string;
  svgClassName?: string;
  fill?: string;
};

export default function Forest({ className, svgClassName }: ForestProps) {
  return (
    <div className={twMerge("w-full", className)}>
      <svg
        className={svgClassName}
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        width="1280.000000pt"
        height="689.000000pt"
        viewBox="0 0 1280.000000 689.000000"
        preserveAspectRatio="xMidYMid meet"
      >
        <metadata>Created by potrace 1.15, written by Peter Selinger 2001-2017</metadata>
        <g
          transform="translate(0.000000,689.000000) scale(0.100000,-0.100000)"
          fill="currentColor"
          stroke="none"
        >
          <path
            fill="currentColor"
            d="M4213 6854 c-28 -32 -59 -135 -72 -241 -13 -101 -39 -181 -99 -298
-36 -72 -62 -106 -127 -170 -46 -44 -98 -105 -118 -135 -34 -54 -63 -84 -160
-163 -26 -21 -52 -47 -58 -59 -11 -20 -10 -21 65 -16 69 5 76 3 76 -13 0 -11
-33 -46 -80 -84 -104 -87 -264 -265 -297 -332 -14 -30 -28 -73 -31 -98 -4 -41
-3 -45 17 -45 13 0 41 11 64 24 54 30 97 38 139 24 18 -6 53 -13 78 -17 l45
-6 -69 -50 c-105 -76 -281 -221 -325 -266 -51 -53 -94 -133 -86 -159 9 -27 15
-26 75 15 56 37 78 42 129 24 25 -9 42 -9 71 1 50 16 75 9 130 -38 l45 -39
-54 -29 c-150 -81 -371 -220 -408 -257 -44 -42 -70 -97 -57 -118 5 -9 39 -10
120 -6 l113 6 41 -34 c23 -19 56 -37 75 -41 l33 -6 -28 -41 c-15 -23 -39 -62
-54 -87 -14 -25 -51 -80 -81 -123 -30 -43 -55 -84 -55 -92 0 -10 16 -15 57
-17 55 -3 58 -4 67 -35 8 -28 5 -39 -20 -74 -28 -40 -50 -144 -33 -155 4 -2 3
-20 -3 -40 -6 -25 -6 -50 3 -83 13 -53 8 -84 -20 -121 l-19 -24 -39 41 c-21
23 -83 71 -138 107 -154 100 -193 130 -173 134 9 2 45 -11 79 -28 49 -25 71
-31 101 -27 33 4 39 2 51 -21 15 -32 63 -38 81 -10 30 49 -83 170 -308 330
-16 11 40 74 76 84 28 8 34 15 38 44 5 33 7 35 39 32 l34 -4 -5 51 c-7 70 -45
115 -139 166 -81 44 -102 75 -50 75 17 0 31 4 31 10 0 5 -34 42 -75 82 -80 76
-111 122 -145 209 -11 30 -33 64 -48 76 -33 28 -11 31 27 4 48 -35 77 -36 105
-6 21 23 32 27 66 23 38 -3 40 -2 40 23 0 41 -40 91 -100 124 -31 17 -68 49
-87 75 -78 105 -141 175 -195 217 -34 26 -68 62 -81 87 -36 69 -150 225 -170
232 -23 8 -51 -18 -85 -76 -14 -25 -42 -65 -61 -90 -20 -25 -54 -70 -76 -100
-21 -30 -63 -81 -92 -113 -68 -75 -69 -92 -6 -83 l48 6 -52 -52 c-53 -54 -102
-139 -107 -184 -2 -13 -27 -54 -57 -90 -56 -68 -67 -87 -68 -116 -1 -15 8 -18
52 -18 47 -1 56 -5 99 -44 l46 -43 -61 -21 c-34 -11 -98 -30 -142 -42 -145
-38 -294 -144 -320 -229 -7 -22 -5 -23 34 -19 32 3 41 0 41 -12 1 -39 14 -43
98 -30 112 17 135 3 52 -33 -34 -15 -66 -27 -71 -27 -18 0 -142 -50 -204 -82
-116 -61 -143 -86 -186 -174 -21 -44 -39 -84 -39 -87 0 -18 34 -4 70 29 35 32
114 63 127 51 2 -3 -8 -41 -23 -86 -15 -44 -25 -84 -21 -87 4 -4 35 0 69 9 35
9 81 18 103 19 36 3 42 7 60 45 21 43 43 71 32 41 -10 -28 11 -31 44 -7 25 18
35 20 48 11 36 -26 62 -32 104 -22 23 5 47 9 52 9 17 0 -129 -129 -147 -129
-10 0 -25 -8 -35 -18 -10 -10 -41 -37 -70 -60 -59 -49 -93 -98 -93 -135 0 -20
-6 -27 -32 -32 -67 -15 -119 -46 -239 -143 -132 -106 -179 -166 -179 -231 0
-37 8 -38 45 -10 18 15 43 23 78 24 29 1 69 10 89 20 37 18 37 18 77 -8 62
-41 84 -37 125 27 31 48 40 55 81 64 29 6 59 6 83 0 20 -5 57 -14 82 -20 46
-11 80 -33 80 -52 0 -16 -98 -85 -129 -92 -60 -12 -350 -132 -436 -180 -27
-15 -77 -55 -110 -89 -33 -34 -99 -88 -147 -119 -130 -85 -214 -193 -206 -263
2 -23 7 -28 28 -26 14 2 52 5 85 7 33 2 74 11 90 20 44 23 60 20 100 -19 l35
-36 74 7 c75 7 126 1 126 -15 0 -5 -53 -28 -117 -52 -78 -29 -145 -62 -198
-98 -44 -30 -123 -83 -175 -118 -52 -36 -104 -73 -115 -83 -11 -11 -45 -30
-76 -43 -61 -28 -73 -39 -83 -79 -3 -15 -18 -35 -33 -45 -47 -32 -94 -85 -89
-100 3 -8 -3 -32 -14 -53 -24 -47 -27 -100 -6 -100 13 0 13 -4 0 -35 -25 -60
-12 -145 22 -145 10 0 32 11 50 25 57 43 128 72 168 67 20 -2 36 -7 36 -11 0
-3 -44 -37 -97 -76 -53 -38 -168 -123 -255 -187 -88 -65 -174 -125 -193 -133
-31 -15 -105 -110 -105 -135 0 -5 21 -10 46 -10 52 0 51 5 24 -91 -27 -94 15
-106 91 -25 77 83 137 105 185 67 18 -14 34 -17 69 -14 37 5 46 3 51 -13 3
-11 10 -28 16 -38 9 -16 5 -24 -20 -47 -16 -15 -38 -31 -48 -37 -34 -19 -7
-24 63 -12 135 24 130 24 138 1 8 -26 21 -27 35 -1 7 14 21 20 43 20 18 0 71
8 119 18 49 10 88 15 88 11 0 -3 -10 -19 -22 -34 -47 -60 16 -74 205 -46 79
12 87 15 82 32 -5 15 -1 19 20 19 33 0 101 -34 108 -55 10 -25 33 -18 128 40
78 47 97 55 140 55 46 0 50 -2 44 -20 -9 -28 13 -26 50 4 27 22 32 23 47 9 9
-8 14 -21 12 -28 -4 -10 4 -12 34 -9 32 4 41 1 45 -13 4 -10 11 -25 16 -33 5
-8 12 -27 16 -42 9 -47 27 -37 81 45 13 21 29 35 33 33 5 -3 14 -26 20 -50 16
-66 39 -70 32 -6 -2 13 -1 13 9 0 8 -13 11 -11 16 8 8 29 30 13 39 -30 9 -37
21 -23 30 34 6 33 10 38 33 38 21 -1 34 -11 56 -47 29 -45 30 -45 38 -22 5 13
7 39 4 57 -2 17 -3 32 -1 32 3 0 25 -10 49 -22 29 -14 47 -30 52 -47 7 -21 9
-14 16 39 5 36 9 80 10 97 l2 33 33 -26 33 -25 35 27 c19 14 35 29 35 32 0 2
-9 20 -21 38 l-20 34 57 25 c31 14 58 25 60 25 1 0 2 -26 1 -57 -2 -54 0 -58
21 -61 32 -5 48 12 68 70 l17 51 24 -64 c25 -68 43 -95 43 -66 0 9 4 17 10 17
5 0 26 15 46 32 28 25 39 29 42 19 9 -27 53 -65 61 -52 5 8 11 3 19 -14 16
-34 46 -32 73 6 16 23 20 39 16 66 -5 30 -4 33 9 22 11 -9 18 -8 34 6 29 26
33 50 16 84 -9 17 -16 45 -16 63 l0 32 45 -44 c40 -40 43 -45 27 -57 -15 -12
-14 -13 9 -7 44 10 41 -11 -9 -62 l-47 -48 50 -7 c36 -5 73 -1 133 14 l82 21
55 -27 c66 -32 69 -33 55 -8 -5 11 -10 30 -10 43 1 22 2 22 25 -7 14 -16 25
-25 25 -18 0 9 10 8 41 -2 52 -19 59 -19 59 -1 0 8 5 18 11 21 6 4 9 1 8 -6
-5 -22 11 -89 20 -89 5 0 20 9 34 20 36 28 40 25 33 -20 -5 -35 -4 -40 12 -40
24 0 90 44 119 79 21 25 21 25 -3 -43 -14 -38 -23 -71 -20 -73 8 -8 69 26 131
72 33 25 62 45 65 45 3 0 3 -32 2 -71 -2 -39 1 -76 6 -81 15 -15 70 17 133 79
l57 55 12 -73 c6 -41 15 -87 19 -104 l8 -30 51 53 c56 58 52 60 72 -33 6 -27
18 -60 26 -73 l15 -23 36 30 36 30 30 -45 c16 -25 35 -43 41 -41 6 2 22 31 35
65 l25 62 31 -30 c35 -34 65 -38 87 -12 18 19 48 103 48 132 0 32 17 32 41 -1
27 -38 96 -89 120 -89 27 0 75 48 107 108 l29 53 43 -48 c54 -59 98 -93 121
-93 20 0 39 47 39 97 0 30 3 33 20 28 10 -3 31 0 46 8 24 13 34 12 85 -6 74
-25 106 -18 117 25 7 28 13 32 44 34 20 2 67 -7 105 -19 67 -22 68 -23 77 -64
6 -24 15 -43 22 -43 7 0 29 23 49 51 21 28 41 47 45 42 3 -4 13 -28 20 -53 7
-25 15 -39 17 -32 3 7 15 24 28 38 l22 24 15 -38 15 -37 12 43 c6 23 16 42 21
42 25 0 59 -22 77 -50 l20 -32 7 25 c6 24 8 24 48 15 24 -6 58 -10 77 -9 38 2
101 26 101 38 0 5 -24 36 -52 68 -48 54 -50 66 -6 27 16 -13 21 -12 52 12 l35
26 -20 35 c-20 34 -20 35 -2 41 10 4 33 13 52 20 18 8 36 14 39 14 2 0 2 -20
0 -44 -12 -106 69 -80 92 29 6 28 7 26 28 -34 12 -36 22 -68 22 -72 0 -4 -19
-9 -42 -11 l-43 -3 99 -96 c55 -53 96 -100 93 -106 -3 -5 2 -22 11 -37 16 -26
96 -66 170 -83 12 -3 22 -7 22 -10 0 -2 -16 -25 -35 -50 -19 -25 -34 -48 -31
-50 2 -2 52 1 112 7 60 5 115 10 122 10 8 0 -2 -27 -27 -74 -32 -61 -37 -75
-25 -80 47 -18 102 7 129 58 11 20 22 36 26 36 3 0 26 -59 50 -131 24 -72 45
-125 47 -117 3 7 18 37 34 66 l31 54 54 -99 c35 -64 52 -88 49 -68 -13 84 -17
127 -11 145 11 36 22 21 28 -37 7 -63 25 -123 37 -123 6 0 43 56 145 219 l27
43 26 -25 c43 -39 66 -47 142 -47 68 0 74 2 105 33 19 19 36 48 40 69 l7 35
73 -44 c40 -24 78 -55 85 -69 17 -31 26 -30 42 6 7 17 15 30 18 30 3 0 18 -41
33 -90 30 -93 40 -111 36 -62 -1 15 -1 45 -1 66 l2 40 30 -29 c17 -16 45 -52
63 -80 l32 -50 5 74 5 75 33 -54 c36 -58 52 -56 52 8 0 17 2 32 5 32 3 0 28
-12 56 -26 28 -14 53 -24 55 -22 2 2 -12 27 -32 56 l-35 52 42 0 c41 0 42 1
25 19 -23 25 -13 61 17 61 20 1 20 2 4 11 -26 15 -20 37 12 44 39 8 174 9 200
0 12 -3 21 -2 21 3 0 6 -54 27 -121 48 -117 37 -120 39 -114 64 3 14 17 36 30
49 l24 25 41 -23 c42 -23 70 -22 70 2 0 39 66 99 217 200 87 58 177 121 200
141 l42 37 -21 34 c-23 40 -18 65 13 65 19 0 20 -2 8 -17 -13 -15 -11 -15 24
-9 21 4 45 10 53 13 11 4 14 -4 14 -36 0 -45 -6 -50 -140 -124 -36 -20 -77
-52 -92 -71 -16 -19 -41 -43 -58 -53 -16 -9 -30 -21 -30 -25 0 -9 9 -8 104 13
54 12 59 11 73 -7 13 -17 15 -18 26 -3 7 10 36 20 67 24 30 4 74 13 98 20 46
14 50 11 26 -23 -9 -13 -13 -30 -10 -39 7 -17 68 -15 193 8 49 9 60 14 56 27
-5 13 0 14 32 9 21 -3 48 -15 60 -27 12 -11 28 -20 36 -20 8 0 45 21 82 46 51
34 79 46 110 47 35 2 42 -1 39 -16 -4 -23 9 -22 42 4 26 21 46 18 46 -6 0 -18
21 -24 42 -13 13 6 20 2 31 -19 8 -16 17 -40 21 -55 3 -16 10 -28 15 -28 5 0
24 22 42 50 17 27 35 47 39 45 4 -3 11 -20 14 -40 9 -46 21 -51 38 -15 17 38
26 38 43 -2 l14 -33 7 43 c5 35 10 42 30 42 14 0 31 -12 44 -30 30 -41 43 -38
38 10 -2 22 -3 40 -2 40 21 0 68 -29 79 -49 l14 -26 1 43 c0 24 5 52 10 63 8
13 7 25 -1 41 l-11 21 31 -22 c32 -23 53 -20 81 13 10 13 9 20 -5 42 -9 14
-15 27 -13 29 2 1 22 12 45 24 l42 21 3 -48 c3 -40 6 -47 25 -50 24 -4 53 39
53 79 0 30 10 18 35 -42 l21 -49 43 36 c23 20 43 35 45 33 2 -2 9 -15 16 -27
8 -16 18 -22 30 -19 12 3 20 -2 23 -14 13 -50 83 13 73 65 -5 24 -4 27 8 17
12 -10 18 -7 32 14 15 22 16 32 6 59 -24 65 -16 71 37 27 27 -23 51 -41 54
-41 4 0 9 16 13 35 5 28 11 35 29 35 13 0 33 -9 46 -20 l24 -20 -63 -61 c-35
-33 -62 -62 -60 -65 3 -2 35 5 72 17 65 19 70 20 109 4 23 -10 48 -19 55 -22
10 -4 10 0 1 21 -15 32 -4 46 16 20 8 -11 14 -14 15 -6 0 9 7 9 31 -3 36 -19
59 -14 59 14 0 19 2 19 41 6 23 -8 43 -15 44 -15 2 0 8 13 13 29 9 25 8 30 -9
34 -10 3 -19 10 -19 15 0 18 44 0 76 -30 23 -22 38 -28 53 -23 12 3 21 11 21
16 0 5 11 7 25 3 21 -5 25 -3 25 17 l0 22 26 -24 26 -24 -7 25 c-4 14 -10 31
-13 38 -7 20 88 5 181 -29 96 -36 117 -36 203 -3 86 32 111 26 221 -53 43 -31
96 -64 118 -74 39 -18 115 -25 128 -13 18 18 -84 140 -159 189 -59 40 -68 50
-28 35 14 -6 39 -10 55 -10 27 0 29 2 18 21 -11 22 -102 127 -156 182 -16 15
-96 79 -179 142 -105 80 -181 129 -248 160 -53 25 -96 47 -96 49 0 2 19 18 42
35 51 38 84 39 110 6 25 -32 45 -32 80 2 l27 26 59 -26 c56 -26 122 -36 122
-19 0 25 -48 75 -123 128 -79 56 -131 85 -342 185 -47 22 -85 49 -115 82 -25
28 -79 87 -121 131 -42 45 -89 105 -104 133 -15 29 -50 80 -77 113 -27 34 -46
65 -43 69 10 17 115 71 136 71 11 0 40 -11 63 -25 67 -39 100 -15 65 49 -25
46 -25 46 13 35 39 -11 88 -12 88 -1 0 4 -16 27 -35 51 -19 24 -35 47 -35 51
0 4 -30 22 -67 39 -38 18 -108 57 -158 86 -92 56 -167 85 -220 85 -16 0 -45
10 -64 23 -67 43 -77 47 -132 47 -33 0 -63 6 -75 15 -10 8 -28 15 -39 15 l-20
1 20 11 c11 7 70 15 131 18 96 6 118 10 157 33 25 15 53 27 62 27 9 0 29 7 45
16 26 16 31 15 91 -5 75 -25 79 -25 79 -6 0 38 -35 77 -117 131 -49 32 -110
77 -136 101 -29 25 -69 49 -96 57 -26 7 -74 28 -108 44 -80 41 -88 63 -35 91
30 16 88 7 164 -25 32 -13 67 -24 78 -24 30 0 26 49 -9 96 -45 62 -157 164
-180 164 -11 0 -50 19 -86 43 -35 23 -79 51 -97 62 -36 22 -83 94 -93 141 -5
28 -3 33 24 44 37 15 89 4 194 -41 65 -27 127 -38 127 -21 0 9 -22 42 -72 109
-35 46 -62 69 -143 118 -103 63 -197 173 -236 278 -17 44 -35 69 -71 100 -27
22 -62 62 -78 88 -16 27 -40 55 -54 64 -22 15 -27 15 -63 -2 -30 -14 -46 -32
-73 -84 -26 -48 -55 -83 -108 -128 -40 -35 -77 -73 -82 -85 -16 -43 -32 -48
-91 -27 -90 32 -322 140 -341 159 -17 17 -17 20 -1 62 21 56 31 70 52 70 9 0
39 12 66 27 38 20 63 26 104 25 30 -1 58 1 62 4 39 24 -151 148 -255 167 -28
5 -82 10 -121 11 -105 2 -145 13 -145 42 0 12 -4 26 -10 29 -17 11 -11 37 10
42 39 10 18 44 -49 78 -50 25 -124 98 -183 179 -74 102 -107 135 -144 142 -61
11 -88 -18 -105 -113 -7 -37 -28 -89 -60 -144 -27 -48 -49 -89 -49 -93 0 -3
11 -6 25 -6 39 0 31 -27 -25 -78 -33 -30 -50 -53 -50 -69 0 -28 -11 -30 -27
-4 -6 10 -24 22 -40 26 -22 5 -31 2 -46 -16 -9 -12 -19 -20 -20 -18 -45 66
-57 75 -129 98 -42 12 -88 30 -104 40 -43 26 -137 138 -123 147 6 3 22 30 34
58 22 49 23 50 30 24 4 -15 13 -32 21 -39 11 -9 16 -6 24 16 10 26 0 81 -26
150 -5 11 1 15 25 15 43 0 39 16 -20 78 -59 63 -161 115 -215 110 -25 -2 -41
4 -61 23 -46 43 -74 57 -151 74 -60 14 -76 22 -97 49 -14 18 -22 40 -20 51 4
15 -7 29 -40 53 -62 45 -247 253 -298 334 -24 39 -48 67 -57 65 -29 -4 -69
-80 -80 -151 -14 -89 -80 -218 -155 -304 -59 -68 -70 -106 -34 -115 18 -5 20
-11 14 -45 -7 -45 3 -57 58 -67 36 -7 37 -8 21 -26 -16 -18 -16 -19 21 -19
l39 0 -29 -30 c-16 -16 -31 -30 -34 -30 -4 0 -41 29 -84 65 -75 63 -95 71
-152 65 -31 -3 -45 -20 -45 -54 -1 -17 -7 -42 -15 -56 -23 -40 -18 -47 32 -55
64 -9 90 -24 144 -82 25 -26 47 -48 50 -49 11 -1 36 -22 59 -48 l25 -29 -34
18 c-19 9 -64 35 -100 57 -36 22 -67 36 -69 30 -2 -6 -20 -8 -41 -5 -68 9
-177 -15 -211 -47 -43 -40 -38 -50 23 -50 28 0 57 -4 63 -8 16 -11 34 -48 34
-72 0 -15 8 -22 29 -26 16 -4 40 -17 52 -30 l23 -24 -160 1 c-199 2 -257 -12
-321 -76 -61 -61 -58 -77 15 -73 56 3 57 2 60 -24 3 -24 9 -29 43 -34 40 -7
45 -11 93 -86 l24 -36 -86 -5 c-48 -3 -99 -8 -113 -12 -23 -6 -30 -1 -63 43
-47 64 -99 228 -121 377 -30 213 -67 290 -136 283 -43 -4 -65 -36 -108 -158
-22 -60 -48 -110 -75 -144 -23 -28 -70 -89 -106 -136 -35 -47 -103 -124 -152
-171 -66 -65 -88 -93 -88 -113 0 -30 12 -32 61 -12 39 15 119 27 118 18 0 -11
-98 -85 -207 -157 -95 -62 -142 -109 -142 -142 0 -9 17 -13 50 -13 46 0 53 -3
68 -30 17 -30 60 -50 110 -50 41 0 23 -16 -66 -59 -85 -40 -175 -64 -350 -91
-77 -13 -101 -31 -164 -123 -43 -63 -36 -82 21 -53 44 23 51 20 51 -19 0 -46
20 -53 83 -31 27 10 73 20 101 23 48 5 52 3 74 -27 13 -17 22 -34 19 -36 -2
-3 -43 -24 -90 -48 l-86 -43 -79 13 c-108 17 -283 66 -289 81 -8 20 26 122 47
143 16 16 28 18 63 14 57 -8 82 16 90 87 8 66 12 70 55 63 51 -8 73 11 79 69
l6 48 56 -6 c61 -6 89 4 75 28 -15 25 -151 145 -186 163 -76 41 -279 123 -353
144 -44 12 -100 33 -125 45 -43 22 -98 71 -89 79 2 2 29 -3 59 -12 30 -9 75
-16 100 -16 l45 -1 3 48 c2 26 6 47 10 47 3 0 25 -7 49 -16 58 -23 77 -14 70
33 -4 20 -18 60 -32 90 -35 70 -176 195 -288 256 -52 28 -90 56 -105 78 l-24
34 39 3 c61 4 77 10 118 42 59 47 90 39 131 -29 41 -71 69 -94 95 -80 18 9 20
19 17 92 -3 75 -1 82 16 82 79 0 8 139 -123 239 -35 27 -83 79 -115 124 -30
42 -89 111 -131 152 -42 41 -105 118 -140 170 -99 148 -142 187 -195 176 -27
-5 -34 -1 -64 41 -50 69 -128 232 -177 370 -24 68 -46 123 -49 123 -3 0 -15
-12 -27 -26z m4825 -1936 c-6 -18 -22 -20 -43 -3 -19 14 -18 14 13 15 22 0 32
-4 30 -12z m-298 -99 c36 -10 85 -18 110 -18 88 -2 126 -58 43 -63 -45 -3 -49
-6 -67 -42 -21 -42 -22 -42 -57 22 -9 16 -41 49 -70 72 -57 44 -76 69 -43 55
11 -4 48 -16 84 -26z m-2101 -517 c48 -24 56 -33 73 -77 10 -27 26 -56 35 -63
33 -24 -37 -2 -94 30 -38 21 -53 36 -53 50 0 12 -19 39 -42 61 l-41 39 34 -7
c19 -3 58 -18 88 -33z m3645 -8 c26 -6 20 -10 -59 -40 -48 -19 -90 -33 -92
-31 -7 8 18 62 35 75 16 12 35 12 116 -4z m-3645 -340 c3 -2 -6 -15 -19 -29
-18 -19 -29 -23 -54 -18 -32 6 -56 27 -56 49 0 9 17 11 63 7 34 -3 64 -7 66
-9z m-2999 -1082 c6 -73 5 -75 -72 -101 l-55 -19 5 52 c4 43 10 57 45 94 56
58 71 53 77 -26z m-425 -1972 c-33 -22 -45 -25 -45 -11 0 5 12 14 28 19 43 16
49 13 17 -8z m3500 -80 c31 0 45 -5 49 -16 9 -23 1 -28 -22 -15 -11 6 -45 14
-76 17 -31 4 -56 9 -56 11 0 2 12 15 28 28 l27 24 3 -25 c3 -21 8 -24 47 -24z"
          />
        </g>
      </svg>
    </div>
  );
}
